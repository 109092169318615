import { TargetTextInput as BaseTargetTextInput, TargetTextArea, TargetNumberInput as BaseTargetNumberInput, TargetMoneyInput as BaseTargetMoneyInput, TargetCheckbox as BaseTargetCheckbox } from '@code-yellow/spider';
import { TargetDateTimePicker as BaseTargetDateTimePicker } from '@code-yellow/spider';
import React from 'react';
import { TargetDatePicker as BaseTargetDatePicker } from '@code-yellow/spider';
import { TargetTimePicker as BaseTargetTimePicker } from '@code-yellow/spider';
import { Form, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LengthUnits from 'store/enums/LengthUnits';
import MaskedInput from 'react-text-mask';
import { t } from 'i18n';
import liveTarget from 'component/HOC/liveTarget'
import inlineTarget from './HOC/inlineTarget';
import { Checkbox } from 'semantic-ui-react';
import { DatePicker as BaseDatePicker } from 'daycy';

// [TODO][SPIDER]
// export * from 'spider/semantic-ui/Target';
export { TargetFile, FilePreview, FileContainer, FormLabel, TargetBase, TargetDateRangePicker, TargetMultiPick, TargetPercentageInput, TargetRadioButtons, TargetSelect, TargetTextArea, getFileNameFromUrl, TargetTimePicker, ErrorLabel } from '@code-yellow/spider';


const NoMarginGroup = styled(Form.Group)`
    margin-bottom: 0 !important;
`;

// Clear button implemented in spider is shit... Keeps breaking when not used in standard way
const ClearButton = styled(Icon)`
    cursor: ${props => props.disabled ? 'auto' : 'pointer'};
    float: right;
    right: 0.5em;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    line-height: 1rem;
    margin: 0!important;
    width: 1em!important;
    height: 1em!important;
    opacity: 0.8!important;
    transition: opacity 0.1s ease!important;
    &:hover {
        opacity: 1!important;
    }
    font-size: 0.86em!important;
    font-family: 'Dropdown'!important;
`

// add placeholder to TargetTextInput
export class TargetTextInput extends BaseTargetTextInput {
    renderContent(props) {
        const { name } = this.props;
        const transKey = `${this.getModelName()}.field.${name}.placeholder`;
        const placeholder = t(transKey);

        if (placeholder !== transKey) {
            props.placeholder = placeholder;
        }

        return super.renderContent(props);
    }
}

export class TargetSpan extends TargetTextInput {

    renderContent(props) {
        return (
            <span {...props} >{this.value}</span>
        );
    }
}

export class TargetNumberInput extends BaseTargetNumberInput {

    renderContent(props) {
        const { props: otherProps, mask } = this.createMask(props);

        return (
            <MaskedInput
                mask={mask}
                value={this.localValue}
                onChange={this.onChange}
                onKeyDown={(e) => {
                    if (e.keyCode === 110 && !e.target.value.includes(',')) {
                        e.target.value += ',';
                    }
                }}
                onBlur={this.initLocalValue}
                render={this.renderInput}
                {...otherProps}
            />
        );
    }
}

export class TargetMoneyInput extends TargetNumberInput {
    static defaultProps = {
        ...BaseTargetMoneyInput.defaultProps,
    };
}

export default class DistanceTargetInput extends BaseTargetNumberInput {
    static defaultProps = {
        ...BaseTargetNumberInput.defaultProps,
        allowDecimal: true,
        decimalLimit: 3,
        label: t('target.distanceTargetInput.defaultLabel'),
    };
    static propTypes = {
        ...BaseTargetNumberInput.propTypes,

        renderUnit: PropTypes.string.isRequired, // Should be LengthUnit
        valueUnit: PropTypes.string.isRequired,
    };

    getConversionNumber() {
        const { renderUnit, valueUnit } = this.props;

        if (renderUnit === valueUnit) {
            return 1;
        }

        switch (renderUnit) {
            case LengthUnits.KILOMETERS:
                switch (valueUnit) {
                    case LengthUnits.MILES:
                        return 1 * 1.609;
                    default:
                        return 1;
                }

            case LengthUnits.MILES:
                switch (valueUnit) {
                    case LengthUnits.KILOMETERS:
                        return 1 / 1.609;
                    default:
                        return 1;
                }
            default:
                return 1;
        }
    }

    fromModel(value) {
        if (typeof value !== 'number') {
            return '';
        }
        return (value * this.getConversionNumber()).toString();
    }

    toModel(value) {
        if (value === '') {
            return null;
        }
        return parseFloat(value) / this.getConversionNumber();
    }

    createMask(props) {
        return super.createMask({ ...props, suffix: ' ' + t(`enums.lengthUnit.${this.props.renderUnit}Short`) })

    }
}
export class NaiveTargetTimePicker extends BaseTargetTimePicker {

    toModel(value) {
        if (!value) {
            return null;
        }

        // Dirty hack to transform local timezone datetime to utc without changing offset
        // This should be fixed in daycy as an option to provide timezone. Now all timepicker are using default.
        // Updated the hack because previous one was buggy when updating already set date
        return value.setZone('utc', { keepLocalTime: true })
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        return value.toUTC();
    }

}


class DatePicker extends BaseDatePicker {
    onOpen() {
        const { onOpen } = this.props;

        super.onOpen();
        onOpen && onOpen();
    }

    onClose() {
        const { onClose } = this.props;

        super.onClose();
        onClose && onClose();
    }
}


export class TargetDatePicker extends BaseTargetDatePicker {
    static propTypes = {
        ...BaseTargetDatePicker.propTypes,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
    };

    renderContent({ disabled, dateProps, timeProps, ...props }) {
        const { onOpen, onClose, clearable } = this.props;

        return (
            // Wrapped in a div so clear icon gets absolute positioning within the input instead of entire component(with label etc.)
            <div style={{ position: 'relative' }}>
                <DatePicker
                    value={this.value}
                    onChange={this.onChange}
                    onOpen={onOpen}
                    onClose={onClose}
                    disabled={disabled}
                    {...props}
                />
                {this.value && clearable ? <ClearButton {...props} data-test-clear-button name='delete' onClick={this.clear}/> : null}
            </div>
        );
    }
}


export class TargetDateTimePicker extends BaseTargetDateTimePicker {
    renderContent({ disabled, dateProps, timeProps, ...props }) {
        return (
            <NoMarginGroup {...props}>
                <BaseTargetDatePicker noLabel
                    data-test-date
                    value={this.value}
                    onChange={this.onChangeDate}
                    width={8}
                    disabled={disabled}
                    onClear={() => this.setValue(null)}
                    {...dateProps}
                />
                <BaseTargetTimePicker noLabel
                    data-test-time
                    value={this.value}
                    onChange={this.onChangeTime}
                    width={8}
                    disabled={disabled}
                    onClear={() => this.setValue(null)}
                    {...timeProps}
                />
            </NoMarginGroup>
        );
    }
}



export const StyledCheckbox = styled(Checkbox)`
    label {
        background: white !important;
        border: 2px solid #757575;
        border-radius: 2px;
        cursor: pointer;
        height: 18px;
        width: 18px;
    }

    label:before {
        display: none;
    }

    label:after {
        border-radius: 2px;
        top: -1px !important;
        left: -1px !important;
        width: 16px !important;
        height: 16px !important;

        background: #757575;
        color: white !important;
    }
`

const FlexContainer = styled.div`
    align-items: center;
    display: flex;

`;

const SmallFlex = styled.div`
    flex: 0 0 auto;
    line-height: 0;
`;

const BigFlex = styled.div`
    flex: 1 1 auto;
`;

export class TargetCheckbox extends BaseTargetCheckbox {

    renderContent({ rightLabel, ...props }) {
        let node = (
            <StyledCheckbox
                checked={this.value}
                onChange={this.onChange}
                {...props}
            />
        );

        if (rightLabel) {
            node = (
                <FlexContainer>
                    <SmallFlex>{node}</SmallFlex>
                    <BigFlex>{this.renderLabel()}</BigFlex>
                </FlexContainer>
            );
        }

        return node;
    }
}


export const TargetNoResizeTextArea = styled(TargetTextArea)`
    textarea {
        resize: none !important;
        height: 38px!important;
        overflow: hidden;
        min-width: 15em !important; // Keep text readable on smaller screens
    }
`;


export const StyledTargetNumberInput = styled(TargetNumberInput)`
  margin-bottom: 10px;
  label {
    font-size: 14px;
    font-weight: bold;
  }

  .input {width: 80%}
  input {
    height: 38px;
    width: 80%;
    font-size: 12px;
  }
  &.backgroundGray {
    input {
      background-color: var(--gray-100) !important;
    }
  }
`;

export const StyledTargetTextInput = styled(TargetTextInput)`
  margin-bottom: 10px;
  label {
    font-size: 14px;
    font-weight: bold;
  }

  .input {width: 80%}
  input {
    height: 38px;
    width: 80%;
    font-size: 12px;
  }
  &.backgroundGray {
    input {
      background-color: var(--gray-100)!important;
    }
  }
`;

export const StyledTargetTextArea = styled(TargetTextArea)`
  margin-bottom: 10px;

  label {
    height: 20px;
    font-size: 14px;
    font-weight: bold;
  }
  .input {  width: 80%}
  textarea {
    height: 75px;
    width: 80%;
    font-size: 12px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 10px;
    border-radius: 3px;
    resize: none;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--gray-400);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--gray-400);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--gray-400);
    }
  }
`;



// Live components
export const LiveTargetMoneyInput = liveTarget(TargetMoneyInput);
export const LiveTargetDatePicker = liveTarget(BaseTargetDatePicker);
export const LiveTargetDateTimePicker = liveTarget(TargetDateTimePicker);
export const LiveTargetTextInput = liveTarget(TargetTextInput);
export const LiveTargetCheckbox = liveTarget(TargetCheckbox);
export const LiveTargetSpan = liveTarget(TargetSpan);

// Inline Live components
export const InlineLiveTargetDatePicker = inlineTarget(LiveTargetDatePicker)
